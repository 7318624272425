import axios from 'axios';
import { ErrorType, KontaktformularRequest } from 'kontaktformular-common';
import { appConfig } from '../configuration';

export interface FondChangeResponse {
  messageType?: ErrorType;
}

export const requestFondChange = async (insuranceNumber: string, body: KontaktformularRequest): Promise<FondChangeResponse> => {
  try {
    return await axios.put(`${appConfig.backendUrl}${insuranceNumber}`, body)
      .then(() => ({messageType: undefined}))
      .catch(error => ({messageType: error.response.data.error}));
  } catch (error) {
    return Promise.reject(error);
  }
};
