import { THEME } from '@eg/elements/Provider';
import { AEM_APP_ID } from '../constants';

export const aemConfig = {
  get aemParams() {
    const fullAemAppId = Object.keys(window.params ?? {}).filter(id => id.includes(AEM_APP_ID))[0];

    return window.params?.[fullAemAppId];
  },
  get backendUrl() {
    return aemConfig.aemParams?.backendDomain;
  },
  get theme() {
    return aemConfig.aemParams?.theme ?? THEME.ergoone;
  },
  get quellsystem() {
    return aemConfig.aemParams?.quellsystem;
  },
  get OENummer() {
    return aemConfig.aemParams?.OENummer;
  }
};
