import { ErrorType } from 'kontaktformular-common';
import React from 'react';

export const getErrorText = (error?: ErrorType): JSX.Element => {
  switch (error) {
    case ErrorType.FIB_NEGATIV:
      return <div data-component-id="kontaktformular-fib-negative-message">
        Ihr Wunsch ist bei uns eingegangen. Eine automatisierte Sofortbearbeitung ist leider nicht möglich.
        Wir werden Ihnen jedoch in den nächsten Tagen die gewünschten Unterlagen für einen Fondswechsel zuleiten.
      </div>;
    case ErrorType.RIESTER:
      return <div data-component-id="kontaktformular-riester-message">Sie haben einen Riestervertrag abgeschlossen und es steht noch kein Kapital für die freie
        Fondsanlage zur Verfügung. Ein Fondswechsel ist
        daher leider nicht möglich. Wenn Sie über Deckungskapital für die freie Fondsanlage verfügen, informieren wir Sie darüber schriftlich in der jährlichen
        Standmitteilung.</div>;
    case ErrorType.DIREKTVERSICHERUNG:
      return <div data-component-id="kontaktformular-directinsurance-message">Sie haben eine Direktversicherung nach § 3.63 EStG abgeschlossen und es steht noch
        kein Kapital für die freie Fondsanlage zur Verfügung. Ein
        Fondswechsel ist daher leider nicht möglich. Wenn Sie über Deckungskapital für die freie Fondsanlage verfügen, informieren wir Sie darüber schriftlich
        in der jährlichen Standmitteilung.</div>;
    case ErrorType.NOT_FOUND:
      return <div data-component-id="kontaktformular-not-found-message">Ihre Nummer ist leider nicht in unserem System hinterlegt. Bitte wenden Sie sich an
        Ihren Berater.</div>;
    default:
    case ErrorType.FALLBACK_ERROR:
      return <div data-component-id="kontaktformular-fallback-message">Leider können wir Ihnen nicht automatisiert weiter helfen, bitte wenden Sie sich an Ihren
        Berater.</div>;
  }
};
