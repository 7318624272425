import { Provider as EEProvider } from '@eg/elements/Provider';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ApplicationRoot from './components/App';
import { aemConfig, trackJsConfig } from './configuration';
import { ROOT_DIV_ID, SPINNER_ID } from './constants';
import { trackJs } from './helper/externalScripts';

const rootElement: HTMLElement = document.getElementById(ROOT_DIV_ID) as HTMLElement;

// Disable AEM Spinner
const spinner = document.getElementById(SPINNER_ID);
if (spinner && spinner.parentNode) {
    spinner.parentNode.removeChild(spinner);
}

// tslint:disable-next-line:no-any
(window as any)._trackJs = trackJsConfig;
// tslint:disable-next-line:no-empty
trackJs.catch(() => {});

ReactDOM.render(
    <EEProvider theme={aemConfig.theme}>
        <ApplicationRoot/>
    </EEProvider>,
    rootElement
);
