import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps, Formik } from 'formik';
import { ErrorType } from 'kontaktformular-common';
import * as React from 'react';
import { scrollToTop } from '../helper/scrolling';
import { requestFondChange } from '../services/api';
import { trackError, trackPageTransition } from '../tracking/tracker';
import { TrackingStates } from '../tracking/TrackingData';
import { getErrorText } from '../types/ErrorType';
import { createContactFormSchema } from '../validation/ContactFormValidation';
import { getErrorMessage } from '../validation/validationHelper';
import './ContactForm.css';

export interface ContactFormData {
}

const ContactForm = (props: ContactFormData) => {

  const [messageType, setMessageType] = React.useState<ErrorType>();
  const [requestProcessed, setRequestProcessed] = React.useState<boolean>(false);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  scrollToTop();

  const MAX_LENGTH_INSURANCE_NUMBER = 8;
  return <div>
    <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          eMail: '',
          eMail2: '',
          insuranceNumber: ''
        }}
        validationSchema={createContactFormSchema()}
        onSubmit={async (values, {setSubmitting}) => {
          setMessageType(undefined);
          const responseResult = await requestFondChange(values.insuranceNumber, {
            vorname: values.firstName,
            nachname: values.lastName,
            email: values.eMail
          });
          if (responseResult.messageType) {
              trackError(TrackingStates.FAILED, responseResult.messageType);
          } else {
              trackPageTransition(TrackingStates.SUCCESS);
          }
          setRequestProcessed(true);
          setMessageType(responseResult.messageType);
        }
        }

    >{({
         errors,
         touched,
         handleSubmit,
         isSubmitting,
         values
       }) => (
        <form onSubmit={handleSubmit}>
          <Field name="firstName">
            {({field}: FieldProps) => (
                <InputRow label="Vorname:"
                          data-component-id={`kontaktformular-${field.name}`}
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={requestProcessed}
                          error={getErrorMessage(errors, touched, isSubmitting, field.name)}
                />
            )}</Field>
          <Field name="lastName">
            {({field}: FieldProps) => (
                <InputRow label="Nachname:"
                          data-component-id={`kontaktformular-${field.name}`}
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={requestProcessed}
                          error={getErrorMessage(errors, touched, isSubmitting, field.name)}
                />
            )}
          </Field>
          <Field name="eMail">
            {({field}: FieldProps) => (
                <InputRow label="E-Mail-Adresse:"
                          name={field.name}
                          data-component-id={`kontaktformular-${field.name}`}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={requestProcessed}
                          error={getErrorMessage(errors, touched, isSubmitting, field.name)}
                />
            )}
          </Field>

          <Field name="eMail2">
            {({field}: FieldProps) => (
                <InputRow label="E-Mail-Adresse wiederholen:"
                          name={field.name}
                          data-component-id={`kontaktformular-${field.name}`}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={requestProcessed}
                          error={getErrorMessage(errors, touched, isSubmitting, field.name)}
                />)}
          </Field>
          <Field name="insuranceNumber">
            {({field}: FieldProps) => (
                <InputRow
                    label="Vertragsnummer:"
                    name={field.name}
                    maxLength={MAX_LENGTH_INSURANCE_NUMBER}
                    size={MAX_LENGTH_INSURANCE_NUMBER}
                    description={'Einen gültigen Vertrag erkennen Sie an einer 8-stelligen Vertragsnummer, beginnend mit den Ziffern\xa03\xa0bis\xa06.'}
                    data-component-id={`kontaktformular-${field.name}`}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={requestProcessed}
                    error={getErrorMessage(errors, touched, isSubmitting, field.name)}
                />)}
          </Field>
          <div style={{textAlign: 'right'}}>
            {!requestProcessed &&
            <Button data-component-id="submit-button"
                    size="large"
                    variant="primary"
                    type="submit">Formular beantragen</Button>
            }
            {requestProcessed &&
            <Button data-component-id="new-fond-switch-button"
                    onClick={() => {
                      setMessageType(undefined);
                      setRequestProcessed(false);
                      values.insuranceNumber = '';
                      touched.insuranceNumber = false;
                      trackPageTransition(TrackingStates.RETRY);
                    }
                    }
                    size="large"
                    variant="primary"
                    type="button">Weiteres Formular beantragen</Button>
            }
          </div>
        </form>
    )}
    </Formik>
    {requestProcessed &&
    <Card accentColor={!messageType ? '#009284' : '#D2054D'} data-component-id="kontaktformular-message-card">
        <p><strong>Vielen Dank für Ihre Anfrage.</strong></p>
      {messageType && getErrorText(messageType)}
      {!messageType && <div data-component-id="kontaktformular-success-message">
          Ihre Anfrage wird bearbeitet. Sie erhalten in Kürze eine E-Mail mit den angeforderten
          Unterlagen. Sollte diese nicht bei Ihnen eintreffen, so wurde diese ggf. durch Ihren Provider
          als Spam geblockt. Bitte wenden Sie sich im Zweifel an Ihren Berater.
      </div>}

    </Card>}
  </div>;
};

export default ContactForm;
