import { aemConfig, trackingConfig } from '../configuration';
import { TRACKING_VERSION } from '../releaseVersion';
import { TrackingStates } from './TrackingData';

export const mapPageData = (statename?: TrackingStates) => {
    return {
        page: {
            attributes: {displayedBrand: 'ERGO'},
            pageInfo: {
                sysEnv: getSysEnv(),
                issueDate: getBuildTime(),
                pageName: getTrackingPageName(statename)
            },
            category: {
                pagePurpose: 'Sale',
                pageType: 'Leadformular'
            }
        }
    };
};

const getBuildTime = () => {
    return `Kontaktformular|${TRACKING_VERSION}`;
};

const getSysEnv = () => {
    return window.location.host;
};

export const getTrackingPageName = (statename?: TrackingStates) => {

    if (aemConfig.aemParams && statename) {
        return `${trackingConfig.shortName}:${aemConfig.aemParams['trackingName' + statename]}`;
    } else {
        return `Wert aus dem AEM Feld Produktkürzel:${statename}`;
    }
};
