import { GlobalTracking } from '@eg/tracking';
import { TrackingData, TrackingStates } from './TrackingData';
import { mapTrackingData } from './trackingDataMapper';
import { mapPageData } from './trackingPageMapper';
import { mapTransactionData } from './trackingTransactionMapper';

const tracker = new GlobalTracking();
let trackingData: TrackingData;
let currentPage: TrackingStates | undefined;

export const trackPageTransition = (targetPage?: TrackingStates) => {
    try {
        currentPage = targetPage;
        tracker.datalayerTrackPageView(createTrackingObject());
        debug();
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

export const trackError = (targetPage?: TrackingStates, errorMessage?: string) => {
    try {
        currentPage = targetPage;
        const trackingObjekt = {
            ...createTrackingObject(),
            miscellaneous: {
                errors: {
                    errorFlag: 'true',
                    errorType: 'FormFailed',
                    errorMessages: errorMessage || ''
                }
            }
        };
        tracker.datalayerTrackPageView(trackingObjekt);
        debug();
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

const createTrackingObject = () => {
    return {
        ...mapPageData(currentPage),
        ...mapTrackingData(trackingData),
        ...mapTransactionData(trackingData, currentPage)
    };
};

export const addTrackingData = (data: TrackingData) => {
    trackingData = {...trackingData, ...data};
};

const debug = () => {
    if (process.env.NODE_ENV === 'development') {
        // tslint:disable-next-line
        console.log(JSON.stringify(window.appEventData.slice(-1).pop(), null, 2));
    }
};
