"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorType;
(function (ErrorType) {
  ErrorType["FIB_NEGATIV"] = "KEIN_AUTOMATISIERTER_FONDWECHSEL";
  ErrorType["RIESTER"] = "RIESTER";
  ErrorType["DIREKTVERSICHERUNG"] = "DIREKTVERSICHERUNG";
  ErrorType["FALLBACK_ERROR"] = "FALLBACK_ERROR";
  ErrorType["NOT_FOUND"] = "NOT_FOUND";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));