import { FormikValues, getIn } from 'formik';
import { FormikErrors, FormikTouched } from 'formik/dist/types';

export const getErrorMessage = (errors: FormikErrors<FormikValues>,
                                touchedFields: FormikTouched<FormikValues>,
                                isSubmitting: boolean,
                                name: string): string | undefined => {
  const errorMessage = getIn(errors, name);
  const touched = getIn(touchedFields, name);
  if (errorMessage && (touched || isSubmitting)) {
    return errorMessage;
  }
  return undefined;
};
