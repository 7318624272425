import * as React from 'react';
import ContactForm from '../pages/ContactForm';

export function App() {
    return <div>
        <ContactForm/>
    </div>;
}

export default App;
