import {
    DatalayerWithoutEvent
} from '@eg/tracking/datalayer.models';
import { trackingConfig } from '../configuration';
import { TrackingData } from './TrackingData';

const createBasisTrackingObject = () => {
    return {
        product: [{
            productInfo: {productName: trackingConfig.productName},
            category: {primaryCategory: trackingConfig.productCategory}
        }],
        miscellaneous: {
            owCompatibility: {contactReason: trackingConfig.contactReason}
        }
    };
};

export const mapTrackingData = (trackingData: TrackingData): DatalayerWithoutEvent => {
    return createBasisTrackingObject();
};
