import { aemConfig } from './aemConfig';

export const trackingConfig = {
  get productName() {
    return aemConfig.aemParams?.trackingKuerzel ?? 'Wert aus dem AEM Feld Produktkürzel';
  },
  get productCategory() {
    return aemConfig.aemParams?.trackingProduktgruppe ?? 'Wert aus dem AEM Feld Produktgruppe';
  },
  get contactReason() {
    return aemConfig.aemParams?.trackingKontaktgrund ?? 'Wert aus dem AEM Feld Kontaktgrund';
  },
  get shortName() {
    return aemConfig.aemParams?.trackingKuerzel;
  },
  get conversionArt() {
    return aemConfig.aemParams?.trackingConversionArt ?? 'Wert aus dem AEM Feld ConversionArt';
  }
};
