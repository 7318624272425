import { BACKEND_PREFIX } from 'kontaktformular-common';
import { DEFAULT_BACKEND_URL } from '../constants';
import { aemConfig } from './aemConfig';

export const appConfig = {
  get backendUrl() {
    const CONFIG_BACKEND_URL = window.CONFIG_BACKEND_URL || process.env.REACT_APP_BACKEND_URL || DEFAULT_BACKEND_URL;

    const baseUrl = aemConfig.backendUrl || CONFIG_BACKEND_URL;

    return buildFullBackendUrl(baseUrl);
  }
};

export const buildFullBackendUrl = (baseUrl: string) => {
  const cleanBaseUrl = baseUrl.replace(/\/$/, '');

  return `${cleanBaseUrl}${BACKEND_PREFIX}/`;
};
