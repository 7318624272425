import { object, ref, Schema, string } from 'yup';

export const emailPattern = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-ZäöüÄOÜ0-9-]+\.)+[a-zA-Z]{2,6}$/;

export function createContactFormSchema(): Schema<{}> {

  return object().shape({
    firstName:
      string()
        .trim()
        .required('Bitte geben Sie einen Vornamen an.'),
    lastName:
      string()
        .trim()
        .required('Bitte geben Sie einen Nachnamen an.'),
    eMail:
      string()
        .trim()
        .required('Bitte geben Sie eine gültige E-Mail Adresse an.')
        .test('validEmail', 'Bitte geben Sie eine gültige E-Mail Adresse an.', value => emailPattern.test(value)),
    eMail2:
      string()
        .trim()
        .required('Bitte geben Sie eine gültige E-Mail Adresse an.')
        // tslint:disable-next-line:no-null-keyword
        .oneOf([ref('eMail'), null], 'Die E-Mail Adressen müssen übereinstimmen.'),
    insuranceNumber:
      string()
        .required('Bitte geben Sie eine Versicherungsnummer an. Einen gültigen Vertrag erkennen Sie an einer 8-stelligen Vertragsnummer, beginnend mit den Ziffern 3 bis 6.')
        .test('validInsuranceNumber', 'Einen gültigen Vertrag erkennen Sie an einer 8-stelligen Vertragsnummer, beginnend mit den Ziffern 3 bis 6.',
          value => /^[3-6][0-9]{7}$/.test(value))
  });
}
