"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ResponseCodes;
(function (ResponseCodes) {
  ResponseCodes[ResponseCodes["SUCCESS"] = 200] = "SUCCESS";
  ResponseCodes[ResponseCodes["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
  ResponseCodes[ResponseCodes["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
  ResponseCodes[ResponseCodes["VALIDATION_FAILURE"] = 422] = "VALIDATION_FAILURE";
  ResponseCodes[ResponseCodes["ERROR"] = 500] = "ERROR";
})(ResponseCodes = exports.ResponseCodes || (exports.ResponseCodes = {}));