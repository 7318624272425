import { DatalayerTransaction, DatalayerTransactionAttributes } from '@eg/tracking/datalayer.models';
import { trackingConfig } from '../configuration';
import { TrackingData, TrackingStates } from './TrackingData';

export const mapTransactionData = (trackingData: TrackingData, statename?: TrackingStates) => {
    return {
        transaction: {
            attributes: mapAttributes(trackingData, statename)
        } as DatalayerTransaction
    };
};

const mapAttributes = (trackingData: TrackingData, statename?: TrackingStates) => {
    const attributes = {} as DatalayerTransactionAttributes;
    const conversionType = getConversionType(trackingData, statename);
    if (conversionType) {
        attributes.conversionType = conversionType;
    }
    return attributes;
};

const getConversionType = (trackingData: TrackingData, statename?: TrackingStates) => {
    switch (statename) {
        case TrackingStates.SUCCESS:
            return trackingConfig.conversionArt;
        default:
            return '';
    }
};
